import React from "react";
import { Mutation } from "react-apollo"
import gql from "graphql-tag";
import { uid } from 'modules/shared'

const SET_TASK_COMPLETION = gql`
  mutation($taskId: ID!, $userId: ID!, $checked: Boolean!) {
    setTaskCompletion(
      userId: $userId,
      taskId: $taskId,
      checked: $checked) {
      currentUser { id taskCompletions { task { id }}}
    }
  }
`

const Task = ({task, completed}) => (
  <Mutation mutation={SET_TASK_COMPLETION}>
    {
      (setTaskCompletion, { data }) => (
        <div>
          <label>
            <input type="checkbox"
              checked={completed ? true : false}
              onChange={() => { setTaskCompletion({ variables: { taskId: task.id, userId: uid(), checked: !completed } })}}
            /> {task.name}
          </label>
        </div>
      )
    }
  </Mutation>
)

export default Task
