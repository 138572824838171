import React from "react";
import { Link, Redirect } from 'react-router-dom'
import style from './review_completion_style'
import classNames from 'classnames'
import { findUserBehaviour, getCurrentUser } from 'modules/shared'
import { Query } from "react-apollo";
import { Loading, Error } from 'modules/common_components'
import BehaviourGuideline from './behaviour_guideline'
import BehaviourLearningLog from './behaviour_learning_log'
import gql from "graphql-tag";
import client from "../modules/apollo-client";
import I18n from 'i18n-js/index.js.erb'
import InfoPopup from './info_popup'

const GET_BEHAVIOUR_COMPLETION = gql`
  query ($behaviourCompletionId: ID!) {
    behaviourCompletion(behaviourCompletionId: $behaviourCompletionId) {
      user {
        id
        name
        userBehaviours {
          behaviour {
            id
          }
          formValues
        }
      }
      manager{
        id
        name
      }
      behaviour {
        id
        name
        signOffGuidelines
        tasks{
          id
          name
        }
      }
      createdAt
      status
      comment
    }
  }
`

const SET_BEHAVIOUR_COMPLETION = gql`
  mutation($userId: ID!, $behaviourId: ID!, $status: String!, $comment: String!) {
    setBehaviourCompletion(
      userId: $userId,
      behaviourId: $behaviourId,
      status: $status,
      comment: $comment) {
      currentUser { id behaviourCompletions { behaviour { id } status comment }}
    }
  }
`

class ReviewCompletionPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {comment: ''}
  }

  setBehaviourCompletion(behaviourCompletion, behaviourStatus, e) {
    if(this.state.comment == ''){
      e.stopPropagation(); 
      e.preventDefault();
      e.nativeEvent.stopImmediatePropagation();
      alert("Comments are mandatory")
      return null
    }

    const behaviourId = behaviourCompletion.behaviour.id,
          userId = behaviourCompletion.user.id,
          comment = this.state.comment,
          status = behaviourStatus

    client.mutate({
      mutation: SET_BEHAVIOUR_COMPLETION,
      variables: {userId, behaviourId, status, comment}
    })
  }

  render () {
    return (
      <Query
        query={GET_BEHAVIOUR_COMPLETION}
        variables={{behaviourCompletionId: this.props.match.params.behaviourCompletionId}}>
        {
          ({ loading, error, data: {behaviourCompletion} }) => {
            const currentUser = getCurrentUser()

            if (loading) return <Loading/>;
            if (error) return <Error error={error}/>;
            if (currentUser.id != behaviourCompletion.manager.id) return <Redirect to='/' />;

            const tasksList = behaviourCompletion.behaviour.tasks.map((task) =>
              <li key={task.id}>
                {task.name}
              </li>
            );

            const user = behaviourCompletion.user,
                  behaviour = behaviourCompletion.behaviour

            return (
              <div className={style.wrapper}>
                <div className={style.header_bar}>
                  <div className={style.back_button}>
                    Sign-off:&nbsp;
                    <strong>{user.name}</strong>
                  </div>
                  <div className={style.title}>{behaviourCompletion.behaviour.name}</div>
                  <div className={style.sub_title}>
                    <span className={style.status}>{behaviourCompletion.status}:</span> &nbsp;
                    {new Date(behaviourCompletion.createdAt).toLocaleDateString("en-AU", { month: 'short', day: 'numeric', year: 'numeric' })} 
                  </div>
                </div>

                <div className={style.content}>
                  <h2>
                    Self Sign Off
                    <InfoPopup text={I18n.t('popups.self_sign_off')} />
                  </h2>

                  <ul>
                    {tasksList}
                  </ul>

                  <BehaviourLearningLog
                    userBehaviour={findUserBehaviour(behaviour, user.userBehaviours)} />

                  <BehaviourGuideline
                    behaviour={behaviour}
                    style={{ paddingBottom: 20 }} />

                  <h2>Your Comments: {behaviourCompletion.comment}</h2>
                  <p>
                    <textarea
                      className={style.textarea}
                      id={'user-comment'}
                      required
                      placeholder="Feedback is mandatory."
                      onChange={(e) => this.setState({ comment: e.target.value })}>{behaviourCompletion.comment}</textarea>
                  </p>
            

                  <Link
                    to="/"
                    onClick={(e) => this.setBehaviourCompletion(behaviourCompletion, 'approved', e)}
                    className={style.button}
                    type="button">Accept</Link>

                  <Link
                    to="/"
                    onClick={(e) => this.setBehaviourCompletion(behaviourCompletion, 'rejected', e)}
                    className={classNames(style.button, style.secondary)}
                    type="button">Send back to employee</Link>
                </div>
              </div>
            )
          }
        }
      </Query>
    )
  }
}

export default ReviewCompletionPage;
