import React from "react";
import style from "./manager_dashboard_style"
import {filterDomainsByRole} from 'modules/shared'

import UserReport from "./user_report"

const ManagerDashboard = ({domains, currentUser, users}) => {

  return (
    <div className={style.wrapper}>
      <h1 className={style.heading}>Users</h1>
      <ul className={style.users_list}>
      {users.map(user => {
        let userDomains = user.role ? filterDomainsByRole(user.role.id, domains) : domains

        return <UserReport key={user.id} user={user} domains={userDomains} currentUser={currentUser}/>
      })}
      </ul>
    </div>
  )
}

export default ManagerDashboard
