import React from "react";
import style from './domain_tabs_style'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

const DomainTabs = ({currentUser, domains, domain}) => (
  <div className={style.domain_tabs}>
    {
      domains.filter((domain) => domain.role.id == currentUser.role.id && domain.capabilities.length > 0).
        map(({ id, name }, i) => (
          <Link key={i}
            to={id}
            className={classNames(style.domain_tab_link, (id == domain.id ? style.current : ''))}
            title={name}>
            {name}
          </Link>
      ))
    }
  </div>
)

export default DomainTabs
