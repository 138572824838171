import React from "react";

const BehaviourIntroduction = ({behaviour, className, style}) => {
  if (!behaviour.introduction) return null

  return (
    <div className={className} style={style}>
      <h2>Introduction</h2> 
      <div dangerouslySetInnerHTML={{ __html: behaviour.introduction }} />
    </div>
  )

}

export default BehaviourIntroduction
