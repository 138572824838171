import React from "react";
import style from './domain_style'
import { Link } from 'react-router-dom'
import LightWindow from "./light_window";
import I18n from 'i18n-js/index.js.erb'
import classNames from 'classnames'

class CoreDomain extends React.Component {
  constructor(props) {
    super(props)
    this.state = {showPopup: false}
  }

  togglePopup() {
    this.setState({
      showPopup: !this.state.showPopup
    });
  }

  render () {
    if (this.props.domain.capabilities.length > 0){
      return <Link to={`/domains/${this.props.domain.id}`} className={style.core}>{this.props.domain.name}</Link>
      }
    else{
      return (
        <div onClick = { this.togglePopup.bind(this) }>
          <div className={style.core}>{this.props.domain.name}</div>
          {this.state.showPopup ? <LightWindow text={I18n.t('core_domain')} closePopup={this.togglePopup.bind(this)} /> : null}
        </div>
      )
    }
  }
}

export default CoreDomain
