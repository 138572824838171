import React from 'react'
import client from "../modules/apollo-client"
import Auth from '../modules/auth'
import { persistCache } from 'apollo-cache-persist';
import style from './header_style'

const handleLogout = () => {
  Auth.logOut()
    .then(_=> window.localStorage.clear())
    .then(_=> client.clearStore())
    .then(_=> window.location = "/")
}

const LogOutButton = () => (
  <a title="Logout" className={style.logout} onClick={handleLogout}>
    <i className="fas fa-sign-out" />
  </a>
)

export default LogOutButton
