import React from "react";
import gql from "graphql-tag";
import client  from 'modules/apollo-client';

const UPDATE_USER_BEHAVIOUR = gql`
  mutation($behaviourId: ID!, $userId: ID!, $formValues: String!) {
    updateUserBehaviour(
      userId: $userId,
      behaviourId: $behaviourId,
      formValues: $formValues) {
      currentUser { id userBehaviours { behaviour { id } behaviourContent }}
    }
  }
`

class BehaviourContent extends React.Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
  }

  componentDidMount() {
    // FIXME: should update more frequently than the native HTML onChange provides
    this.form.current.addEventListener('change', this.handleFieldChange);
    this.form.current.behaviourContentComponent = this; // the event listener needs to talk to this component
  }

  componentWillUnmount() {
    this.form.current.removeEventListener('change', this.handleFieldChange)
  }

  handleFieldChange() {
    // Note in this event-listener context, `this` refers to the form DOM element
    // Call a method on the component object to access the component as `this`
    this.behaviourContentComponent.saveFormValues();
  }

  saveFormValues() {
    const {behaviour, currentUser} = this.props;
    const formValues = $(this.form.current).serialize();
    client.mutate({
      mutation: UPDATE_USER_BEHAVIOUR,
      variables: {userId: currentUser.id, behaviourId: behaviour.id, formValues: formValues}
    });
  }

  getBehaviourContent() {
    const ub = this.getUserBehaviour();
    if (ub)
      return {__html: ub.behaviourContent }
    else
      return {__html: this.props.behaviour.content }
  }

  getUserBehaviour() {
    for (let userBehaviour of this.props.currentUser.userBehaviours) {
      if (userBehaviour.behaviour.id == this.props.behaviour.id)
        return userBehaviour
    }
  }

  render() {
    return <form
      ref={this.form}
      onSubmit={ev => ev.preventDefault()}
      className={this.props.className}
      dangerouslySetInnerHTML={this.getBehaviourContent()} />
  }
}

export default BehaviourContent
