import React from "react";
import I18n from 'i18n-js/index.js.erb'
import InfoPopup from './info_popup'

const BehaviourGuideline = ({behaviour, className, style}) => {
  if (!behaviour.signOffGuidelines) return null

  return (
    <div className={className} style={style}>
      <h2>
        Guidelines for Demonstration of Competence
       <InfoPopup text={I18n.t('popups.signoff_guidelines')} />
      </h2>
      <div dangerouslySetInnerHTML={{ __html: behaviour.signOffGuidelines }} />
    </div>
  )

}

export default BehaviourGuideline
