import React from "react";
import { Link } from 'react-router-dom'
import style from './detail_page_style'
import Task from './task'
import SignOffButton from './sign_off_button'
import InfoPopup from './info_popup'
import BehaviourIntroduction from './behaviour_introduction'
import BehaviourContent from './behaviour_content'
import BehaviourGuideline from './behaviour_guideline'
import { isTaskCompleted, findBehaviourCompletion, getDomainFromDomains, getBehaviourFromDomains } from 'modules/shared'
import { Query } from "react-apollo";
import APP_QUERY from 'modules/app_query.jsx'
import { Loading, Error } from 'modules/common_components'
import I18n from 'i18n-js/index.js.erb'


var comment;

const DetailPage = ({ match }) => (
  <Query query={APP_QUERY}>
    {
      ({ loading, error, data: {domains, currentUser, currentUser: {taskCompletions, behaviourCompletions}} }) => {
        if (loading) return <Loading/>;
        if (error) return <Error error={error}/>;

        const domain = getDomainFromDomains(match.params.domainId, domains);
        const behaviour = getBehaviourFromDomains(match.params.behaviourId, domains);

        return <div className={style.detail_page}>
          <div className={style.header_bar}>
             <Link to={`/domains/${domain.id}`} className={style.back_button}>
              <i className="far fa-chevron-double-left"></i>
              {domain.name}
             </Link>
             <div className={style.title}>{behaviour.name}</div>
            <div className={style.sub_title}>{behaviour.levelsForRole.map(level => level.name).join(" – ")}</div>
          </div>
          <BehaviourIntroduction behaviour={behaviour} className={style.content}/>
          <div className={style.tasks}>
            <h2>
              Self Sign Off
              <InfoPopup text={I18n.t('popups.self_sign_off')}/>
            </h2>
            {
              behaviour.tasks.map((task, i) => (
                <Task task={task} key={i} completed={isTaskCompleted({task, taskCompletions})} />
              ))
            }
          </div>
          <BehaviourGuideline behaviour={behaviour} className={style.content} />
          <BehaviourContent behaviour={behaviour} currentUser={currentUser} className={style.content} />
          <div className={style.signoff_box}>
            <h2>Sign-off</h2>
              {
                {
                  undefined: <SignOffButton behaviour={behaviour} taskCompletions={taskCompletions}/>,
                  requested: <p>Your manager has been notified that this behaviour requires sign-off.</p>,
                  approved: <p>Your manager has signed off this behaviour.</p>,
                  rejected: <div>
                    <p>Your manager has asked you to revise this behaviour</p>
                    <SignOffButton behaviour={behaviour} taskCompletions={taskCompletions}/>
                  </div>
                }[findBehaviourCompletion(behaviour, behaviourCompletions).status]
              }
              {
                (comment = findBehaviourCompletion(behaviour, behaviourCompletions).comment) ? <blockquote>{comment}</blockquote> : null
              }
            </div>
          </div>
        }
      }
  </Query>
)

export default DetailPage

