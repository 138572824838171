import React from "react";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";
import { uid, completedTasks } from 'modules/shared'

const SET_BEHAVIOUR_COMPLETION = gql`
  mutation($behaviourId: ID!, $userId: ID!, $status: String!) {
    setBehaviourCompletion(
      userId: $userId,
      behaviourId: $behaviourId,
      status: $status) {
      currentUser { id behaviourCompletions { behaviour { id } status comment }}
    }
  }
`

const SignOffButton = ({behaviour, taskCompletions}) => (
  <Mutation mutation={SET_BEHAVIOUR_COMPLETION}>
    {
      (setBehaviourCompletion, { data }) => (
        <button disabled={completedTasks(behaviour.tasks, taskCompletions) < behaviour.tasks.length}
          onClick={() => setBehaviourCompletion({ variables: { userId: uid(), behaviourId: behaviour.id, status: 'requested' } })}>
          Request Manager Sign-off
        </button>
      )
    }
  </Mutation>
)

export default SignOffButton;