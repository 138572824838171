import React from "react";
import style from './role_interest_style';
import gql from "graphql-tag";
import client from "../modules/apollo-client";

const UPDATE_USER_INTERESTS = gql`
  mutation($userId: ID!, $roleName: String!) {
    updateUserInterests(
      userId: $userId,
      roleName: $roleName) {
      currentUser { id interests }
    }
  }
`

class RoleInterest extends React.Component {
  isRoleSelected() {
    var interests = this.props.currentUser.interests
    return interests.includes(this.props.role)
  }

  updateUserInterests() {
    const userId = this.props.currentUser.id,
          roleName = this.props.role

    client.mutate({
      mutation: UPDATE_USER_INTERESTS,
      variables: {userId, roleName}
    })
  }

  render() {
    return (
      <div className={style.role_interest}>
        <label className={style.role_interest_container}>
          Interested in this role?
          <input type="checkbox" defaultChecked={this.isRoleSelected()} onChange={() => this.updateUserInterests()} />
          <span className={style.role_interest_checkmark}></span>
        </label>
      </div>
    );
  }
}

export default RoleInterest