import React from 'react';
import { Query } from 'react-apollo';
import { Loading, Error } from 'modules/common_components'
import APP_QUERY from 'modules/app_query.jsx'
import OctagonalMatrix from "./octagonal_matrix"
import ManagerDashboard from "./manager_dashboard"
import { filterDomainsByRole } from 'modules/shared'

const HomePage = () => (
  <Query query={APP_QUERY}>
    {
      ({ loading, error, data: {domains, currentUser, users, roles} }) => {
        if (loading) return <Loading/>;
        if (error) return <Error error={error}/>;

        const currentUserDomains = currentUser.role ? filterDomainsByRole(currentUser.role.id, domains) : domains
        if (/^(head of sales|manager)/i.test(currentUser.role.name)) {
          return <ManagerDashboard domains={domains} currentUser={currentUser} users={users}/>
        }
        return <OctagonalMatrix domains={currentUserDomains} currentUser={currentUser} roles={roles}/>
      }
    }
  </Query>
)

export default HomePage
