import React from 'react'
import { Link } from 'react-router-dom'
import style from './header_style'
import logo from 'images/logo_name.svg'
import LogOutButton from './log_out_button'
import NotificationPopover from './notification_popover'


const Header = ({currentUser}) => (
  <header className={style.header}>
    <Link to="/" className={style.logo}>
      <img src={logo} />
    </Link>
    <div className={style.user}>
      <span className={style.bell}>
        <NotificationPopover
            behaviourCompletions={currentUser.requestedManagedBehaviourCompletions}
            enabled={(/^(head of sales|manager)/i.test(currentUser.role.name)) }
          />
      </span>
      <span className={style.icon}><i className="fas fa-user"/></span>
      <span className={style.name}><strong>{currentUser.name}</strong></span>
      <span className={style.role_level}>
        {currentUser.role ? currentUser.role.name : null} {currentUser.level ? currentUser.level.name : null}
      </span>
      {currentUser.admin ? <Link to="/admin" className={style.admin}>admin</Link> : ""}
      <span><LogOutButton /></span>
    </div>
  </header>
)

export default Header
