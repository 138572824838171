import client from "../modules/apollo-client"
import gql from "graphql-tag";
import _ from "lodash"

const getCurrentUser = () => (
  client.cache.readQuery({query: gql`{ currentUser { id name email } }`}).currentUser
)

const uid = () => getCurrentUser().id

const isBehaviourCompleted = (behaviour, behaviourCompletions) => {
  const bc = findBehaviourCompletion(behaviour, behaviourCompletions)
  return bc.status == "approved"
}

const findBehaviourCompletion = (behaviour, behaviourCompletions) =>
  behaviourCompletions.find(bc => bc.behaviour.id == behaviour.id) || {}

const findUserBehaviour = (behaviour, userBehaviours) =>
  userBehaviours.find(ub => ub.behaviour.id == behaviour.id) || {}

const isTaskCompleted = ({ task, taskCompletions }) => ( taskCompletions.find(tc => tc.task.id == task.id) )

const completedTasks = (tasks, taskCompletions) => {
  var n = 0;
  tasks.forEach((task) => {
    if (isTaskCompleted({
        task,
        taskCompletions
      }))
      n += 1;
  })
  return n;
}

const filterDomainsByRelevance = (relevance, domains) => {
  return domains.filter(domain => {
    return domain.relevance === relevance
  })
}

const filterDomainsByRole = (roleId, domains) => {
  return domains.filter(domain => {
    if (roleId) return domain.role.id === roleId
    return domain
  })
}

const getUserDomainsWithApprovals = (user, domains) => {
  let domainsWithApprovals = []

  user.behaviourCompletions.map(b => {
    let domain = getDomainFromBehaviour(b.behaviour.id, domains)

    if (!domainsWithApprovals.find(d => d.id === domain.id)) {
      domainsWithApprovals.push(domain)
    }
  })

  return domainsWithApprovals
}

const getDomainFromDomains = (id, domains) => {
  for (let domain of domains) {
    if (domain.id == id)
      return domain
  }
}

const getBehaviourFromDomains = (id, domains) => {
  for (let domain of domains) {
    for (let capability of domain.capabilities) {
      for (let behaviour of capability.behaviours) {
        if (behaviour.id == id)
          return behaviour
      }
    }
  }
}

const getBehavioursFromDomain = (domain) => {
  let domainBehaviours = []

  domain.capabilities.map(c => c.behaviours.map(behaviour => {
    domainBehaviours.push(behaviour)
  }))

  return domainBehaviours
}

const getDomainFromBehaviour = (id, domains) => {
  for (let domain of domains) {
    for (let capability of domain.capabilities) {
      for (let behaviour of capability.behaviours) {
        if (behaviour.id == id)
          return domain
      }
    }
  }
}

const getTotalCapabilities = (userId, domain) => {
  let capabilities = []

  domain.capabilities.map(c => capabilities.push(c))

  return capabilities.length
}

const getAllDomainTasks = (domain, taskCompletions) => {
  let behaviours = getBehavioursFromDomain(domain),
      tasks = {completed: [], all: []}

  behaviours.map(b => b.tasks.map(task => {
    tasks.all.push(task)
    if (isTaskCompleted({task, taskCompletions})) tasks.completed.push(task)
  }))

  return tasks
}

const getAllDomainTasksPerLevel = (domain, taskCompletions, level) => {
  let behaviours = getBehavioursPerLevel(getBehavioursFromDomain(domain), level),
      tasks = {completed: [], all: []}

  behaviours.filter(b => b.levels.map(l => l.id).includes(level.id))
    .map(b => b.tasks.map(task =>
    {
      tasks.all.push(task)
      if (isTaskCompleted({task, taskCompletions})) tasks.completed.push(task)
    })
  )
  
  return tasks
}

const getBehavioursPerLevel = (behaviours, level) => (
  behaviours.filter((behaviour) => {
    return behaviour.levels.map(bl => bl.name).includes(level.name)
  })
)


const percentCompletion = (completed, total) => {
  if (total === 0) return 0

  return Math.round((completed/total) * 100)
}

export {
  getCurrentUser,
  uid,
  isTaskCompleted,
  completedTasks,
  isBehaviourCompleted,
  findBehaviourCompletion,
  findUserBehaviour,
  filterDomainsByRelevance,
  filterDomainsByRole,
  getUserDomainsWithApprovals,
  getDomainFromDomains,
  getBehaviourFromDomains,
  getBehavioursFromDomain,
  getDomainFromBehaviour,
  getTotalCapabilities,
  getAllDomainTasks,
  getAllDomainTasksPerLevel,
  percentCompletion
}
