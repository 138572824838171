import React from "react";
import style from './light_window_style';
import RoleInterest from "./role_interest";

class LightWindow extends React.Component {
  renderRoleInterest() {
    if (this.props.role) {
      return (<RoleInterest currentUser={this.props.currentUser} role={this.props.role} />)
    } else {
      return null
    }
  }

  render() {
    return (
      <div className={style.modal} >
        {this.renderRoleInterest()}
        <div className={style.modal_inner}>
          <div dangerouslySetInnerHTML={{ __html: this.props.text }} /> 
        </div>
        <div onClick={this.props.closePopup} className={style.modal_outer}></div>
      </div>
    );
  }
}

export default LightWindow


  