import React from "react";
import classNames from 'classnames';
import style from "./domain_report_style"
import {getAllDomainTasksPerLevel, getBehavioursFromDomain, percentCompletion} from "modules/shared"
import BehaviourReport from "./behaviour_report"

class DomainReport extends React.Component {
  constructor(props) {
    super(props)
    this.state = {visible: false}
  }

  returnTaskStatus() {
    let tasks = getAllDomainTasksPerLevel(this.props.domain, this.props.user.taskCompletions, this.props.user.level),
        percentComplete = percentCompletion(tasks.completed.length, tasks.all.length),
        domainApproved = JSON.parse(this.props.user.domainsApprovedHash)[this.props.domain.id],
        statusClass = ''

    if (percentComplete > 0) statusClass = 'in_progress'
    if (percentComplete == 100 && domainApproved) statusClass = 'complete'
    
    return (
      <div className={classNames(style.domain_status, style[`${statusClass}`])}>
        <div className={style.progress_bar}>
          <div className={style.progress_bar_inner} style={{width: `${percentComplete}%`}}></div>
        </div>
        <div className={style.domain_percentage}>
          {percentComplete}%
        </div>
        <div className={style.domain_tasks}>
          ({tasks.completed.length} of {tasks.all.length})
        </div>
      </div>
    )
  }

  renderBehaviourReport() {
    let behaviours = getBehavioursFromDomain(this.props.domain)

    if (!this.state.visible) return null

    return (
      <div className={style.table}>
        {behaviours.map(behaviour => (
          <BehaviourReport
            key={`${this.props.user.id}-${behaviour.id}`}
            behaviour={behaviour}
            user={this.props.user} />
        ))}
      </div>
    )
  }

  toggleAccordion() {
    this.setState({visible: !this.state.visible})
  }

  render () {
    let activeClass = this.state.visible ? 'is-active' : ''

    return (
      <div>
        <div onClick={() => this.toggleAccordion()} title={this.props.domain.relevance} className={classNames(style.domain, style[`${activeClass}`])}>
          <h3 className={style.domain_heading}>
            {this.props.domain.name}
          </h3>
          {this.returnTaskStatus()}
          <i className="fa fa-chevron-down"/>
        </div>
        {this.renderBehaviourReport()}
      </div>
    )
  }
}

export default DomainReport
