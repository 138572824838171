import React from "react";
import style from './user_report_style'
import classNames from 'classnames'
import DomainReport from "./domain_report"
import {getAllDomainTasksPerLevel, percentCompletion} from "modules/shared"

class UserReport extends React.Component {
  constructor(props) {
    super(props)
    this.state = {visible: false}
  }

  calculateTotalProgress() {
    let completedCount = 0, totalCount = 0

    this.props.domains.filter(domain => {
      if (domain.relevance != "elective") return domain
    }).map(domain => {
      return getAllDomainTasksPerLevel(domain, this.props.user.taskCompletions, this.props.user.level)
    }).map(tasks => {
      completedCount += tasks.completed.length
      totalCount += tasks.all.length
    })

    return percentCompletion(completedCount, totalCount)
  }

  isCurrentUser() {
    return this.props.user.id === this.props.currentUser.id
  }

  toggleAccordion() {
    this.setState({visible: !this.state.visible})
  }

  renderDomainReport() {
    if (!this.state.visible) return null
    return (
      <div className={style.user_table}>
        {this.props.domains.filter(domain => {
          if (domain.capabilities.length > 0 ) return domain
        }).map(domain => (
          <DomainReport
            key={`${this.props.user.id}-${domain.id}`}
            user={this.props.user}
            domain={domain} />
        ))}
      </div>
    )
  }

  render () {
    if (this.isCurrentUser()) return null

    let statusClass,
        activeClass = this.state.visible ? 'is-active' : ''

    if (this.calculateTotalProgress() == 100 && this.props.user.domainsRequiredApproved)
      statusClass = 'complete'
    else if(this.calculateTotalProgress() > 0)
      statusClass = 'in_progress'

    return (
      <li className={classNames(style.user, style[`${activeClass}`])}>
        <header onClick={() => this.toggleAccordion()} className={style.user_header}>
          <div className={style.user_details}>
            <h2 className={style.user_name}>{this.props.user.name}</h2>
            <div className={style.user_role}>{this.props.user.role.name}</div>
            <div className={style.user_level} >{this.props.user.level.name}</div>
          </div>
          <div className={classNames(style.user_status, style[`${statusClass}`])}>
            <div className={style.progress_bar}>
              <div className={style.progress_bar_inner} style={{width: `${this.calculateTotalProgress()}%`}}></div>
            </div>
            <div className={style.user_percentage}>
              {this.calculateTotalProgress()}%
            </div>
          </div>
          <i className="fa fa-chevron-down"/>
        </header>
        {this.renderDomainReport()}
      </li>
    )
  }
}

export default UserReport
