import React from "react";
import style from "./octagonal_matrix_style"
import LightWindow from "./light_window";
import classNames from 'classnames'

class Role extends React.Component {
  constructor(props) {
    super(props)
    this.state = {showPopup: false}
  }

  togglePopup() {
    this.setState({
      showPopup: !this.state.showPopup
    });
  }

  render () {

    return (
      <div>
        <div className={classNames(style.role, style[`role_${this.props.position}`])} onClick={this.togglePopup.bind(this)}>
          {this.props.role.name}
        </div>

        {this.state.showPopup ?
          <LightWindow text={this.props.role.description} currentUser={this.props.currentUser} role={this.props.role.name} closePopup={this.togglePopup.bind(this)} />
          : null
        }

      </div>
    )
  }
}

export default Role
