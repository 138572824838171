import React from "react";
import style from "./behaviour_report_style"
import {isTaskCompleted, percentCompletion} from 'modules/shared'

const BehaviourReport = ({behaviour, user}) => {
  let completedBehaviourTasks = getCompletedTasks(behaviour.tasks, user.taskCompletions)

  function getCompletedTasks(tasks, taskCompletions) {
    return tasks.filter(task => {
      if (isTaskCompleted({task, taskCompletions})) return task
    })
  }

  function getPercentageLabel(completed, all) {
    let percentage = percentCompletion(completed.length, all.length)
    let behaviourStatus = JSON.parse(user.behavioursStatusHash)[behaviour.id]
    
    if (behaviourStatus == "approved") {
      return <span className={style.complete}><i className="fa fa-check"/>Complete</span>
    } else if (behaviourStatus == "requested") {
      return <span className={style.requested}><i className="fa fa-bell"/>Requested</span>
    } else if (behaviourStatus == "rejected") {
      return <span className={style.rejected}><i className="fa fa-exclamation-circle"/>Rejected</span>
    } else if (percentage > 0) {
      return <span className={style.in_progress}><i className="fa fa-clock"/>In Progress</span>
    }
  }

  return (
    <div className={style.behaviour}>
      <div className={style.behaviour_heading}>{behaviour.name} ({behaviour.levels.map( level => level.name ).join(", ")})</div>
      <div className={style.behaviour_status}>
        <span className={style.behaviour_percentage}>{getPercentageLabel(completedBehaviourTasks, behaviour.tasks)}</span>
      </div>
    </div>
  )
}

export default BehaviourReport
