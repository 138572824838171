import React from 'react'
import Popover, { ArrowContainer } from 'react-tiny-popover'
import style from './notification_popover_style'
import NotificationIcon from './notification_icon'
import { Link } from 'react-router-dom'

class NotificationPopover extends React.Component {
  constructor(props) {
    super(props) 
    this.state = { isPopoverOpen: false }
  }

  contents () {
    if (this.props.behaviourCompletions.length == 0) {
      return (
        <div style={{ padding: '10px' }}>No pending sign offs</div>
      )
    } else {
      return (
        <div>
          <div className={style.heading}>Requested sign offs</div>
          <ul className={style.ul}> {
            this.props.behaviourCompletions.map(({ id, behaviour, user, createdAt }, i) => (
              <li key={i}>
                <Link className={style.link} to={`/behaviour_completions/${id}/edit`}>
                  <div className={style.date}>
                    {new Date(createdAt).toLocaleDateString("en-AU", { month: 'short', day: 'numeric' })} 
                  </div>
                  <span className={style.user}>{user.name} </span>
                  <span className={style.behaviour} >{behaviour.name}</span>
                </Link>
              </li>
            ))
          } 
          </ul>
        </div>
      )
    }
  }

  render () {
    if (!this.props.enabled) return null

    return (
      <Popover
        isOpen={this.state.isPopoverOpen}
        position={'bottom'}
        content={({ position, targetRect, popoverRect }) => (
          <ArrowContainer
              position={position}
              targetRect={targetRect}
              popoverRect={popoverRect}
              arrowColor={'#371F68'}
              arrowSize={10} >
              <div
                  className={style.container}
                  onClick={() => this.setState({ isPopoverOpen: !this.state.isPopoverOpen })}>
                  {
                    this.contents()
                  }
              </div>
          </ArrowContainer>
        )}>
        <NotificationIcon
          count={this.props.behaviourCompletions.length}
          handleClick={() => this.setState({ isPopoverOpen: !this.state.isPopoverOpen })}
        />
      </Popover>
    ) 
  }
}

export default NotificationPopover
