import React from "react";
import { Link } from 'react-router-dom'
import style from './domain_style'
import CoreDomain from './core_domain'

const Domain = ({domain}) => {

  if (domain && domain.relevance === 'core') {
   return <CoreDomain domain={domain} />
  }

  if (domain) {
    let position = {left: domain.position[0] + '%', top: domain.position[1] + '%'}
    let complete = domain.isSignedOff ? {fill : '#9faf21'} : null

    function linkHref(domain) {
      if (domain.hasSingleBehaviour == true) {
        return `/domains/${domain.id}/behaviours/${domain.behaviourID}`
      } else {
        return `/domains/${domain.id}`
      }
    } 

    return (
      <Link
        to={linkHref(domain)}
        className={style[domain.relevance]}
        style={position}>
        <svg style={complete} viewBox="0 0 120 120">
          <g transform="translate(10.000000, 10.000000)">
            <path d="M27.2186088,105 L-5,72.7813912 L-5,27.2186088 L27.2186088,-5 L72.7828119,-5 L105,27.2186764 L105,72.7813236 L72.7828119,105 L27.2186088,105 Z"></path>
          </g>
        </svg>
        <div className={style.label}>
          {
            domain.isSignedOff
              ? <i className="fas fa-trophy-alt fa-3x" />
              : null
          }
          <h2 className={style.heading}>{domain.name}</h2>
          <div className={style.percent}>{domain.percentComplete}%</div>
        </div>
      </Link>
    )
  }

  return null
}

export default Domain
