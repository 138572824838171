import React from "react";
import Domain from "./domain"
import Role from "./role"
import style from "./octagonal_matrix_style"
import { filterDomainsByRelevance, percentCompletion } from 'modules/shared'
import classNames from 'classnames'

const OctagonalMatrix = ({domains, currentUser, roles}) => {
  const mandatoryDomains = filterDomainsByRelevance('mandatory', domains),
    electiveDomains = filterDomainsByRelevance('elective', domains),
    coreDomain = filterDomainsByRelevance('core', domains)[0],
    taskCompletionIDs = currentUser.taskCompletions.map(t => t.task.id)

  function markCompletedBehaviours(behaviours) {
    const approved = behaviours.filter(b => b.status === 'approved')

    approved.map(behaviour => {
      domains.map(d => d.capabilities.map(c => c.behaviours.map(b => {
        if (behaviour.behaviour.id === b.id) b.completed = true
      })))
    })
  }

  function generateDomainOctagons(relevance, domains, level) {
    const mCoords = [[50, 30], [40, 51], [60, 51], [50, 72]],
      eCoords = [[30, 31], [70.4, 72], [70.4, 31], [30, 72], [60, 92], [40, 92]],
      coords = relevance === 'mandatory' ? mCoords : eCoords

    markCompletedBehaviours(currentUser.behaviourCompletions)

    return domains.map((domain, i) => {
      let completedBehaviourCount = 0,
        totalBehaviourCount = 0,
        totalTaskCount = 0,
        completedTaskCount = 0,
        capabilities = domain.capabilities

      capabilities.map(c =>
        c.behaviours.filter(b =>
          b.levels.map(l => l.id).includes(level.id)
        ).map(b => {
          totalBehaviourCount++
          if (b.completed === true) completedBehaviourCount++
          b.tasks.map(t => {
            totalTaskCount++
            if (taskCompletionIDs.includes(t.id)) completedTaskCount++
          })
        })
      )

      domain.percentComplete = percentCompletion(completedTaskCount, totalTaskCount)
      domain.isSignedOff = percentCompletion(completedBehaviourCount, totalBehaviourCount) == 100
      domain.position = coords[i]

      if (capabilities.length == 1 && capabilities[0].behaviours.length > 0) {
        domain.hasSingleBehaviour = true
        domain.behaviourID = capabilities[0].behaviours[0].id
      }

      if (domain.position) return <Domain domain={domain} key={i} />
    })
  }


  let other_roles = roles.filter((role) => role.id !== currentUser.role.id && role.description);
  let middle = Math.floor(other_roles.length / 2)
  let left = other_roles.slice(0, middle);
  let right = other_roles.slice(middle, other_roles.length);


  return (
    <div className={style.stage}>
      <section className={style.stage_inner}>
        <Domain domain={coreDomain} />
        {generateDomainOctagons('mandatory', mandatoryDomains, currentUser.level)}
        {generateDomainOctagons('elective', electiveDomains, currentUser.level)}

        <div className={classNames(style.roles, style.left)}>
          {left.map((role, i) => {
            return <Role role={role} currentUser={currentUser} key={role.id} position={i} />
          })}
        </div>

        <div className={classNames(style.roles, style.right)}>
          {right.map((role, i) => {
            return <Role role={role} currentUser={currentUser} key={role.id} position={i} />
          })}
        </div>

      </section>
    </div>
  )
}

export default OctagonalMatrix
