import gql from "graphql-tag";

export default gql`
  {
    domains {
      id
      name
      relevance
      position
      role {
        id
      }
      capabilities {
        id
        name
        behaviours {
          id
          name
          content
          introduction
          signOffGuidelines
          levelsForRole {
            id
            name
          }
          levels{
            id
            name
            position
          }
          tasks {
            id
            name
          }
        }
      }
    }
    levels {
      id
      name
      roleIds
    }
    currentUser {
      id
      name
      email
      admin
      interests
      role {
        id
        name
      }
      level {
        id
        name
      }
      taskCompletions {
        task {
          id
        }
      }
      behaviourCompletions {
        behaviour{
          id
          name
        }
        status
        comment
      }
      userBehaviours {
        behaviour {
          id
        }
        behaviourContent
      }
      requestedManagedBehaviourCompletions {
        id
        behaviour {
          id
          name
        }
        user {
          id
          name
        }
        status
        comment
        createdAt
      }
    }
    users {
      id
      name
      email
      domainsRequiredApproved
      domainsApprovedHash
      behavioursStatusHash
      role {
        id
        name
      }
      level {
        id
        name
        position
      }
      taskCompletions {
        task {
          id
        }
      }
      behaviourCompletions {
        behaviour {
          id
        }
        status
        comment
      }
    }
    roles {
      id
      name
      description
    }
  }
`
