import React from "react";
import style from './capabilities_matrix_style'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { completedTasks, isBehaviourCompleted, findBehaviourCompletion } from 'modules/shared'

const styleForBehaviour = (behaviour, currentUser) => {
  if (isBehaviourCompleted(behaviour, currentUser.behaviourCompletions))
    return style.completed
  else if (completedTasks(behaviour.tasks, currentUser.taskCompletions) > 0)
    return style.in_progress
  else
    return style.not_yet_started
}

const CapabilitiesMatrix = ({ domain, currentUser, numberOfLevels }) => (
  domain.capabilities.map(({ id, name, behaviours }) => {

    return (
      <div className={style.column} key={id}>
        <div className={style.behaviours}>
          {
            behaviours.map(behaviour => (
              <Link className={classNames(style.behaviour, style[`levels_${behaviour.levelsForRole.length}`], styleForBehaviour(behaviour, currentUser))}
                  key={`${id}-${behaviour.id}`}
                  to={`${domain.id}/behaviours/${behaviour.id}`}
                  title={`${name}
Level: ${behaviour.levelsForRole.map( level => level.name ).join()}` }>
                <div className={style.status}>
                  {
                    isBehaviourCompleted(behaviour, currentUser.behaviourCompletions)
                      ? <div className={style.complete}><i className="fas fa-award fa-3x" /> <div>Completed</div></div>
                      : [
                        findBehaviourCompletion(behaviour, currentUser.behaviourCompletions).comment
                          ? <i key={2} className="fas fa-comment fa-3x" /> : null,
                        <div key={1}>{completedTasks(behaviour.tasks, currentUser.taskCompletions)} of {behaviour.tasks.length} Behaviours </div>
                        ]
                  }
                </div>
              </Link>
            )).reverse()
          }
        </div>
        <div className={style.cap} key={id} title={name}>
          <div>{name}</div>
        </div>
      </div>
    )

  })
)

export default CapabilitiesMatrix
