import App from 'components/app';
import React from 'react'
import { render } from 'react-dom'
import '@babel/polyfill'
import 'normalize.css'
import client from "../modules/apollo-client"
import { ApolloProvider } from "react-apollo";
import("@fortawesome/fontawesome/js/all");

document.addEventListener('DOMContentLoaded', () => {
  render(
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>,
    document.body.appendChild(document.createElement('div')),
  )
})
