import ApolloClient from "apollo-boost";
import { InMemoryCache } from 'apollo-cache-inmemory';
import { persistCache } from 'apollo-cache-persist';

const cache = new InMemoryCache();

persistCache({
  cache,
  storage: window.localStorage,
});

const defaults = {
  domainId: null,
  behaviourId: null
};

const csrfToken = document.querySelector('meta[name=csrf-token]').getAttribute('content');

const client = new ApolloClient({
  cache,
  credentials: 'same-origin',
  headers: {
    'X-CSRF-Token': csrfToken
  },
  clientState: {
    defaults,
    resolvers: {}
  }
});

export default client;
