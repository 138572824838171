const csrfToken = document.querySelector('meta[name=csrf-token]').getAttribute('content');

const Auth = {
  logOut: () => (
    fetch('/users/sign_out', {
      method: "DELETE",
      credentials: 'same-origin',
      headers: {
        'X-CSRF-Token': csrfToken
      }
    }) 
  )
}


export default Auth
