import React from "react";
import style from "./behaviour_learning_log_style";

const BehaviourLearningLog = ({userBehaviour}) => {
  const formValues = JSON.parse(userBehaviour.formValues || "{}")
  const learningLog = formValues['journal']

  if (!learningLog) return null

  return (
    <div>
      <h2>Learning Journal</h2>
      <div className={style.journal} dangerouslySetInnerHTML={{ __html: learningLog }} />
    </div>
  )

}

export default BehaviourLearningLog
