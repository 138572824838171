import React from 'react'
import style from './notification_icon_style'
import buttonStyle from './btn_icon_style'

const NotificationIcon = ({count, handleClick}) => {
  const notificationClass = (count <= 0) ? '' : `${style.notification_badge}`
  return (
    <button onClick={handleClick} className={`${buttonStyle.btnTransparentIcon} ${notificationClass}`} data-count={count}>
      <i
        className="fa fa-bell"
        />
    </button>
  )
}

export default NotificationIcon
