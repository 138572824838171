import React from 'react'

export default ({error}) => {
  if (error)
  return <div className="error" style={{padding: '70px 0 20px', marginBottom: -70, backgroundColor: '#f9f2f4', color: '#c7254e'}}>
      Error: <span>{error.message}</span>
    </div>
  else
    return null;
}
