import React from 'react'
import { HashRouter as Router, Switch, Route } from 'react-router-dom'

import Header from './header'
import MatrixPage from './matrix_page'
import DetailPage from './detail_page'
import HomePage from './home_page'
import NotFoundPage from './not_found_page'
import ReviewCompletionPage from './review_completion_page';
import { Loading, Error } from 'modules/common_components'

import { Query } from "react-apollo";
import APP_QUERY from 'modules/app_query.jsx'


const App = () => (
  <Router>
    <Query query={APP_QUERY} pollInterval={15000}>
      {
        ({ loading, error, data }) => {
          if (loading) return <Loading/>;

          return <>
            <Error error={error}/>
            <Switch>
              <Route exact path='/admin' component={() => { window.location = '/admin'; return null; }} />
              <Route exact path="/" component={HomePage}/>
              <Route exact path="/domains/:domainId" component={MatrixPage}/>
              <Route exact path="/domains/:domainId/behaviours/:behaviourId" component={DetailPage}/>
              <Route exact path="/behaviour_completions/:behaviourCompletionId/edit" component={ReviewCompletionPage}/>
              <Route component={NotFoundPage}/>
            </Switch>
            <Header currentUser={data.currentUser}/>
          </>
        }
      }
    </Query>
  </Router>
)

export default App
