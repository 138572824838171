import React from 'react'
import classNames from 'classnames'
import style from './matrix_page_style'
import DomainTabs from './domain_tabs'
import CapabilitiesMatrix from './capabilities_matrix'
import { Query } from "react-apollo";
import APP_QUERY from 'modules/app_query.jsx'
import { Loading, Error } from 'modules/common_components'
import { getDomainFromDomains } from 'modules/shared'

const MatrixPage = ({match}) => (
  <Query query={APP_QUERY}>
  {
    ({ loading, error, data: {domains, levels, currentUser} }) => {
      if (loading) return <Loading/>;
      if (error) return <Error error={error}/>;

      const domain = getDomainFromDomains(match.params.domainId, domains);

      levels = levels.filter(({roleIds}) => roleIds.includes(currentUser.role.id))

      return <div className={style.matrix_page}>
        <div className={style.header}>
          <ul className={style.key}>
            <li className={style.key_progress}>In Progress</li>
            <li className={style.key_completed}>Completed</li>
          </ul>
        </div>
        <div className={style.wrapper}>
          <div className={style.levels}>
            {levels.map(({id, name}, i) => (
              <div key={i} title={`Level ${i + 1}`} className={classNames(style.level, style[`level-${i+1}`] )}>
                {name}
              </div>
            ))}
          </div>

          <div className={style.capabilities}>
            <CapabilitiesMatrix domain={domain} currentUser={currentUser} />
          </div>
        </div>

        <DomainTabs currentUser={currentUser} domains={domains} domain={domain} numberOfLevels={levels.length + 1} />
      </div>
    }
  }
  </Query>
)

export default MatrixPage
